import React from 'react';

interface iContentProps {
    children: JSX.Element | JSX.Element[] | string | string[] | null;
}

const Content: React.FC<iContentProps> = ({
    children = null,
}) => {
    return (
        <div className="wrapper container">
            {children}
        </div>
    )
}

export default Content;