import React, { useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';

import ApiSourceEnum from '../../enums/ApiSourceEnum';
import ContextActionTypesEnum from '../../enums/ContextActionTypesEnum';

import { IUser } from '../../interfaces/User';

interface IAction {
    type: string;
    payload: any;
}

interface IState {
    currentUser: IUser | null;
}

const initialState: IState = {
    currentUser: null,
};

const reducer = (state: IState = initialState, action: IAction) => {

    switch (action.type) {
        case ContextActionTypesEnum.SetUser: {
            const currentUser = action.payload;

            return {
                ...state,
                currentUser
            };
        }
        default:
            throw new Error();
    }
};

export const GeneralContext = React.createContext<{
    state: typeof initialState;
    dispatch: (action: IAction) => void;
}>({
    state: initialState,
    dispatch: () => { }
});

export function GeneralContextProvider(props: any) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    useEffect(() => {
        axios
            .get<IUser>(ApiSourceEnum.GetUser)
            .then((response: AxiosResponse) => {

                dispatch({
                    type: ContextActionTypesEnum.SetUser,
                    payload: response.data
                })

            })
            .catch(() => { });
    }, [dispatch])


    return (
        <GeneralContext.Provider value={{ state, dispatch }}>{props.children}</GeneralContext.Provider>
    );
}
