import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from "react-router";
import axios, {AxiosResponse} from 'axios';

import Container from '../../components/Container';
import ContainerHeader from '../../components/ContainerHeader';
import ContainerContent from '../../components/ContainerContent';
import Preloader from '../../components/Preloader';
import Button from '../../components/Button';
// import InputSearch from '../../components/InputSearch';

import { ISurvey } from '../../interfaces/Survey';
import RouterPathEnum from '../../enums/RouterPathEnum';
import ApiSourceEnum from '../../enums/ApiSourceEnum';


import SurveyStatusEnum from '../../enums/SurveyStatusEnum';

const Surveys: React.FC = () => {
    const history = useHistory<{ push: () => void }>();
    const [surveys, setSurveys] = useState<ISurvey[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [searchString, setSearchString] = useState<string>(``);

    useEffect(() => {
        setIsLoading(true);

        axios
            .get<ISurvey[]>(ApiSourceEnum.Surveys)
            .then((response:AxiosResponse) => {
                (Array.isArray(response.data) === true) && setSurveys(response.data);
                setIsLoading(false);
            })
            .catch(() => {});
    }, []);

    const handleDetailClick = (row: ISurvey) => (event: React.MouseEvent) => {
        event.preventDefault();
        history.push(RouterPathEnum.SurveyDetail.replace(':id', `${row.id}`));
    }

    // const handleInputSearchChange = (searchString : string) => setSearchString(searchString);

    return (
        <Container>
            <ContainerHeader
                caption="Surveys"
            />
            {(isLoading === true) ? (
                <ContainerContent>
                    <Preloader />
                </ContainerContent>
            ) : (
                <ContainerContent>
                    <table className="table table-surveys">
                        <thead>
                            <tr>
                                <th className="table-head-cell cell-id">Id</th>
                                <th className="table-head-cell">Name</th>
                                <th className="table-head-cell">Date</th>
                                <th className="table-head-cell">Status</th>
                                <th className="table-head-cell">Items</th>
                                <th className="table-head-cell">Completed</th>
                                <th className="table-head-cell cell-actions">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {surveys?.map((row, index) => {
                                const surveyStatus: string = SurveyStatusEnum[row.status] ?? `none`;
                                return (
                                    <tr key={index}>
                                        <td className="table-cell cell-id">{row.id}</td>
                                        <td className="table-cell cell-name">{row.name}</td>
                                        <td className="table-cell">{row.date && new Date(row.date).toLocaleDateString('en-US')}</td>
                                        <td className={classNames('table-cell cell-status', [
                                            `cell-status--${surveyStatus.toLocaleLowerCase()}`
                                        ])}>
                                            {surveyStatus}  
                                        </td>
                                        <td className="table-cell">{row.itemsCount}</td>
                                        <td className="table-cell">{row.progress}%</td>
                                        <td className="table-cell cell-actions">
                                            <Button 
                                                isPrimary
                                                onClick={handleDetailClick(row)}
                                            >
                                                Detail
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </ContainerContent>
            )}
        </Container>
    )
}

export default Surveys;