import React from 'react';
import { Link } from 'react-router-dom';
import UserMenu from './../UserMenu';

import { ReactComponent as IconLogo } from "../../icons/logo.svg";

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="wrapper header__enter">
                <div className="header__navbar">
                    <Link
                        className="header-logo"
                        to="/"
                    >
                        <IconLogo />
                        <div className="header-logo__caption">Admin panel</div>
                    </Link>
                </div>
                <UserMenu />
            </div>
        </header>
    )
}

export default Header;