import React from 'react';
import classNames from 'classnames';

interface iButtonProps {
	isCurtain?: boolean;
	className?: string;
	children: any | null;
	isPrimary?: boolean;
	isSecondary?: boolean;
	isBusy?: boolean;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent) => void | null;
}

const Button: React.FC<iButtonProps> = ({
	className = null,
	children = null,
	isPrimary = false,
	isSecondary = false,
	isBusy = false,
	disabled = false,
	onClick,
	...otherProps
}) => {
	return (
		<button
			className={classNames(
				'button',
				{
					'button-primary': isPrimary === true,
					'button-secondary': isSecondary === true,
					'button--busy': isBusy === true,
				},
				className
			)}
			disabled={disabled}
			onClick={onClick}
			{...otherProps}
		>
			{children}
		</button>
	);
}

export default Button;