import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.request.use(function (config) {

    config.withCredentials = true;
    
    return config;
});


axios.interceptors.response.use(
    (response) => response,
    (error) => {
	
        if (error.response.status === 401) 
            window.location.href = `${process.env.REACT_APP_API}/UserAuth?returnUrl=${window.location.href}`;

        if (error.response.status === 403) 
            window.location.href = `${process.env.REACT_APP_SNAP}`;

        return Promise.reject(error);
    }
);