import React from 'react';

interface iContentProps {
    // children?: JSX.Element | JSX.Element[] | string | string[],
    children?: any,
    caption?: string,
}

const Content: React.FC<iContentProps> = ({
    children = null,
    caption = null,
}) => {
    return (
        <div className="container-header">
            <div className="container-header__column">
                <div className="container-header__caption">{caption}</div>
            </div>
            {(children) && (
                <div className="container-header__column">
                    {children}
                </div>
            )}
        </div>
    )
}

export default Content;