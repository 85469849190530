import React from 'react';

interface iContentProps {
    children?: JSX.Element | JSX.Element[] | string | string[],
    caption?: string,
}

const Content: React.FC<iContentProps> = ({
    children = null,
}) => {
    return (
        <div className="container-content">
            {children}
        </div>
    )
}

export default Content;