import React, { useContext } from 'react';
import classNames from 'classnames';

import { GeneralContext } from './../../components/GeneralContext';

import { ReactComponent as IconGear } from "../../icons/gear.svg";

interface iUserMenuProps {
    className?: string;
}

const UserMenu: React.FC<iUserMenuProps> = ({
    className = null,
}) => {

    const { state:{
        currentUser
    }, dispatch} = useContext(GeneralContext);


    const userInitials: string = (currentUser) ? currentUser?.firstName.charAt(0) + currentUser?.lastName.charAt(0) : '?';

    const h = () =>{
        dispatch({
            type: 'SET_USER',
            payload: {
                firstName: 'Adfgdfg',
                lastName: 'Bdfgdfg',
            }
        })
    }

    return (
        <div onClick={h} className={classNames('user-menu header-navbar__dropdown', className)}>
            <span className="user-menu-initials" title={(currentUser) ? `${currentUser?.firstName} ${currentUser?.lastName}` : ``}>
                {userInitials.toLocaleUpperCase()}
            </span>
            <div className="user-menu-item user-menu-item--back-snap">
                <a className="user-menu-item__link" href={process.env.REACT_APP_ANALYTIC}>
                    <IconGear
                        fill="#ffffff"
                        className="icon"
                    />
                    <span className="user-menu-item__caption">Back to analytics</span>
                </a>
            </div>
            <div className="user-menu-item user-menu-item--back-snap">
                <a className="user-menu-item__link" href={process.env.REACT_APP_SNAP}>
                    <IconGear
                        fill="#ffffff"
                        className="icon"
                    />
                    <span className="user-menu-item__caption">Back to snap</span>
                </a>
            </div>
        </div>
    )
}

export default UserMenu;