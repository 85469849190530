import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Header from './components/Header';
import Surveys from './routes/Surveys';
import Survey from './routes/Survey';

import {
  GeneralContextProvider,
} from './components/GeneralContext';

import RouterPathEnum from './enums/RouterPathEnum';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <GeneralContextProvider>
        <Header />
        <Switch>
          <Route component={Surveys} path={RouterPathEnum.Surveys} exact />
          <Route component={Survey} path={RouterPathEnum.SurveyDetail} />
          <Redirect from="/*" to={RouterPathEnum.Surveys} />
        </Switch>
      </GeneralContextProvider>
    </BrowserRouter>
  )
}

export default App