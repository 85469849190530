import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios, {AxiosResponse} from 'axios';

import Container from '../../components/Container';
import ContainerHeader from '../../components/ContainerHeader';
import ContainerContent from '../../components/ContainerContent';
import Preloader from '../../components/Preloader';
import Button from '../../components/Button';

import { ISurveyExtended } from '../../interfaces/Survey';

import SurveyStatusEnum from '../../enums/SurveyStatusEnum';
import RouterPathEnum from '../../enums/RouterPathEnum';
import ApiSourceEnum from '../../enums/ApiSourceEnum';

const Survey: React.FC = () => {
    const history = useHistory<{ push: () => void }>();
    const match = useRouteMatch<{ id: string }>();
    // const refInputRespondents = useRef<HTMLInputElement>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isUploadingRespondents, setIsUploadingRespondents] = useState<boolean>(false);
    const [isCalculationProcess, setIsCalculationProcess] = useState<boolean>(false);

    const [survey, setSurvey] = useState<ISurveyExtended | null>(null);
    const [file, setFile] = useState<File | null>(null);

    useEffect(() => {
        if (isLoading === true)
            axios
                .get<ISurveyExtended[]>(`${ApiSourceEnum.Survey}/${match.params.id}`)
                .then((response:AxiosResponse) => {
                    setSurvey(response.data);
                    setIsLoading(false);
                })
                .catch(() => {});

    }, [match.params.id, isLoading]);


    const handleBackToListScreenClick = (event: React.MouseEvent) => {
        event.preventDefault();
        history.push(RouterPathEnum.Surveys);
    }


    const handlInputFileRespondentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const file: File = (event.target.files as FileList)[0] || null;
        (file && file.size > 0) && setFile(file);
    }

    const handlUploadRespondentsClick = (event: React.MouseEvent) => {
        event.preventDefault();

        if (file){
            const formData:FormData = new FormData();
            formData.append('file', file);

            setFile(null);
            setIsUploadingRespondents(true);
            
            const requestData:FormData = new FormData();
            requestData.append('file', file);
            requestData.append('id', match.params.id);


            // *******************************************
            // method: 'POST',
            // credentials: 'include',
            // headers: {
            //    'Accept': 'application/json'
            // },
            // body: formData
            // ********************************************

            axios
                .post(`${process.env.REACT_APP_API}${ApiSourceEnum.DeleteRespondents}`, requestData)
                .then(() => {
                    setIsUploadingRespondents(false);
                    setIsLoading(true);
                })
                .catch(() => {});
        }
    
    }

    const handleRestartCalculationClick = () => {
        setIsCalculationProcess(true);

        axios
            .get(ApiSourceEnum.RegenerateSurvey, {
                params: {
                    id: match.params.id,
                }
            })
            .then(() => {
                setIsCalculationProcess(false);
                setIsLoading(true);
            })
            .catch(() => {});
    }

    const handlRefreshClick = () => setIsLoading(true);

    const isDisableProcessing: boolean =  (survey?.status !== SurveyStatusEnum.Closed || survey?.isGenerated === false);
 
    return (
        <Container>
            <ContainerHeader
                caption={(survey) ? survey.name : `Survey`}
            >
                <Button
                    isPrimary
                    onClick={handleBackToListScreenClick}
                >
                    Back to surveys
                </Button>
            </ContainerHeader>
            {(isLoading === true) ? (
                <ContainerContent>
                    <Preloader />
                </ContainerContent>
            ) : (
                <ContainerContent>
                    <div className="flex-row">
                        <div className="flex-column">
                            {(survey) && (
                                <fieldset className="card-information">
                                    <legend>Survey:</legend>
                                    <div className="information">
                                        <div className="information-item">Id: {survey?.id}</div>
                                        <div className="information-item">Create date: {survey && new Date(survey.date).toLocaleDateString('en-US')}</div>
                                        <div className="information-item">Total items: {survey?.totalItems}</div>
                                        <div className="information-item">Status: {survey.status && SurveyStatusEnum[survey.status]}</div>
                                    </div>
                                    <Button
                                        isPrimary
                                        onClick={handlRefreshClick}
                                    >
                                        Refresh
                                    </Button>
                                </fieldset>
                            )}
                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="flex-column">
                            <fieldset>
                                <legend>Remove respondents</legend>
                                <div className="step-number">1</div>
                                <div>
                                    <Button
                                        isSecondary
                                        className="file-button"
                                        disabled={isDisableProcessing}
                                    >
                                        {(file) ? file.name : 'Choose file csv'}
                                        <input
                                            className="file-button__input"
                                            type="file"
                                            accept=".csv"
                                            onChange={handlInputFileRespondentsChange}
                                            value=""
                                        />
                                    </Button>
                                </div>
                                <div className="step-number">2</div>
                                <Button
                                    isSecondary
                                    onClick={handlUploadRespondentsClick}
                                    disabled={(file === null) ? true : false}
                                    isBusy={isUploadingRespondents}
                                >
                                    Upload file
                                </Button>
                            </fieldset>
                        </div>
                        <div className="flex-column">
                            <fieldset>
                                <legend>Restart calculation</legend>
                                <Button
                                    isSecondary
                                    onClick={handleRestartCalculationClick}
                                    disabled={isDisableProcessing}
                                    isBusy={isCalculationProcess}
                                >
                                    Restart calculation
                                </Button>
                                <p dangerouslySetInnerHTML={ {__html: (survey) ? survey.regeneratingStatus : ``} } />
                            </fieldset>
                        </div>
                    </div>
                </ContainerContent>
            )}
        </Container>
    )
}

export default Survey;